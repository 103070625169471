import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"Source_Sans_3\",\"arguments\":[{\"display\":\"swap\",\"fallback\":[\"sans-serif\"],\"style\":[\"italic\",\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--font-stack-sans\",\"weight\":\"variable\"}],\"variableName\":\"fontSourceSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"adjustFontFallback\":\"Times New Roman\",\"display\":\"swap\",\"fallback\":[\"serif\"],\"src\":[{\"path\":\"../node_modules/@guill3m/fonts/bw-darius/bw-darius-light.woff2\",\"style\":\"normal\",\"weight\":\"200\"},{\"path\":\"../node_modules/@guill3m/fonts/bw-darius/bw-darius-regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"../node_modules/@guill3m/fonts/bw-darius/bw-darius-regular-italic.woff2\",\"style\":\"italic\",\"weight\":\"400\"},{\"path\":\"../node_modules/@guill3m/fonts/bw-darius/bw-darius-bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"../node_modules/@guill3m/fonts/bw-darius/bw-darius-black.woff2\",\"style\":\"normal\",\"weight\":\"900\"}],\"variable\":\"--font-stack-serif\"}],\"variableName\":\"fontBwDarius\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/layout.module.scss");
